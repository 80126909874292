<template>
  <main class="mr-4">
    <div class="mx-auto md:w-9/12">
      <trac-back-button>Back</trac-back-button>
      <h3 class="my-6 font-bold text-sm">Security</h3>
    </div>

    <div
      class="shadow-lg bg-white rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12 py-16"
    >
      <h2 class="text-left text-sm font-bold">Settings to help you keep your account secure</h2>
      <div class="grid grid-cols-2 gap-x-2">
        <div @click="$router.push({name: 'SecurityDevices'})" class="shadow-md col-span-2 lg:col-span-1 px-6 pt-4 pb-0 rounded-lg ">
            <div class="flex my-4 cursor-pointer">
                <div class="w-16 bg-blue-100 h-16 rounded-sm flex items-center justify-center">
                <img src="@/assets/svg/tv.svg" alt="tv" />
                </div>
                <div class="flex-1 px-4 py-4">
                <h3 class=" font-semibold text-sm">Your Devices</h3>
                <p class="text-xs">Where you are signed in</p>
                </div>
                <div class="w-16 h-16 rounded-sm flex items-center justify-center">
                <img src="@/assets/svg/chev-right.svg" alt="tv" />
                </div>
            </div>
        </div>
        <div @click="$router.push({name: 'SecurityOTPSetting'})" class="shadow-md col-span-2 lg:col-span-1 px-6 pt-4 pb-0 rounded-lg ">
            <div class="flex my-4 cursor-pointer">
                <div class="w-16 bg-blue-100 h-16 rounded-sm flex items-center justify-center">
                <img src="@/assets/svg/otp-icon.svg" alt="tv" />
                </div>
                <div class="flex-1 px-4 py-4">
                <h3 class=" font-semibold text-sm">OTP Verification Settings</h3>
                <p class="text-xs">Manage OTP verifications</p>
                </div>
                <div class="w-16 h-16 rounded-sm flex items-center justify-center">
                <img src="@/assets/svg/chev-right.svg" alt="tv" />
                </div>
            </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>